<template>
  <v-card>
    <v-card-title>
      <v-row align="center">
        <v-col cols="3">
          <v-icon size="50">$plant</v-icon>
        </v-col>
        <v-col cols="9" align="center" justify="center">
          <span class="title">
            <router-link :to="pilesView">{{ title }}</router-link>
          </span>
        </v-col>
      </v-row>
    </v-card-title>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th />
            <!-- added empty header to re-align columns -->
            <th v-for="header in headers" :key="header.label">
              <div align="center">
                <v-avatar :color="header.color" size="36"
                  ><v-icon class="mx-2" fab color="white">{{ header.icon }}</v-icon></v-avatar
                >
              </div>
              <div align="center">{{ translations[header.label] }}</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(period, index) in periods" :key="index">
            <td>{{ translations[period.label] }}</td>
            <td v-for="header in headers" :key="header.label" align="center">
              {{ toFloat(data[index][header.label]) }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-card-text align="center">
      <div>n° cumuli attivi: {{ numberOfPiles }}</div>
    </v-card-text>
  </v-card>
</template>

<script>
import { translations } from "../utils/common";
import { mdiEqual, mdiArrowUp, mdiArrowDown } from "@mdi/js";
export default {
  name: "PlantsCard",

  props: {
    title: {
      type: String,
      default: () => ""
    },

    data: {
      type: Array,
      default: () => []
    },

    numberOfPiles: {
      type: Number,
      default: () => 0
    },

    plant: {
      type: Object,
      default: () => {}
    }
  },

  async created() {
    this.pilesView = {
      path: "/piles",
      query: {
        plantId: this.plant.id
      }
    };
  },

  data: () => ({
    headers: [
      { label: "remainder", icon: mdiEqual, color: "main" },
      { label: "load", icon: mdiArrowDown, color: "#ffcc00" },
      { label: "unload", icon: mdiArrowUp, color: "green" }
    ],
    periods: [{ label: "weekly" }, { label: "monthly" }, { label: "yearly" }],
    pilesView: {}
  }),

  methods: {
    toFloat(value) {
      return parseFloat(value).toFixed(2);
    }
  },

  computed: {
    translations: () => translations
  }
};
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border: none;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr {
  border: none;
}

tbody > tr:hover {
  background-color: transparent !important;
}

.title a {
  text-decoration: none;
}
</style>
