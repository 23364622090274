<template>
  <main class="ma-4">
    <simple-date-header :date="translations.plants"></simple-date-header>

    <!-- plants -->
    <transition-group tag="div" class="grid-plant-section" name="grid-plant-section">
      <div class="d-inline-flex mt-5" v-for="plant in plants" :key="plant.id">
        <plants-card style="width: 100%" :plant="plant" :title="plant.name" :data="getStatus(plant)" :numberOfPiles="plant.status.numberOfPiles"></plants-card>
      </div>
    </transition-group>

    <!-- new plant button -->
    <div>
      <v-btn @click="openSaveNewPlantDialog()" class="ma-4" fab style="position: fixed; bottom: 0; right: 0" color="main">
        <v-icon color="white"> mdi-plus </v-icon>
      </v-btn>
    </div>

    <!-- new plant dialog -->
    <v-dialog v-model="dialog" max-width="700px" @keydown.enter="disabled ? null : savePlant(plant)">
      <v-card class="pa-2">
        <v-card-title>
          <v-row class="ma-2" justify="center">
            <h3 class="main--text">
              {{ dialogTitle }}
            </h3>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="3" align="center">
              <v-icon size="120">$plant</v-icon>
            </v-col>
            <v-col cols="9">
              <v-row>
                <v-col cols="3">
                  <v-subheader>{{ translations.name }}</v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-text-field ref="name" v-model="plant.name" outlined hide-details dense></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-subheader>{{ translations.description }}</v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-text-field v-model="plant.description" outlined hide-details dense></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="main" class="white--text" depressed :disabled="disabled" @click="savePlant(plant)">
            {{ translations.confirm }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </main>
</template>
<script>
import { translations } from "@/utils/common";
import SimpleDateHeader from "@/components/SimpleDateHeader.vue";
import Plants from "../graphql/Plants.gql";
import savePlant from "../graphql/SavePlant.gql";
import PlantsCard from "../components/PlantCard.vue";

export default {
  components: {
    SimpleDateHeader,
    PlantsCard
  },

  apollo: {
    plants: {
      query: Plants,
      fetchPolicy: "network-only",
      result({ data }) {
        this.plants = data.plants;
      }
    }
  },

  data: () => ({
    plants: [],
    maxRouteRowSize: 5,
    dialog: false,
    dialogTitle: "",
    plant: {
      name: "",
      description: ""
    }
  }),

  methods: {
    async savePlant(plant) {
      await this.$apollo
        .mutate({
          mutation: savePlant,
          variables: { plantDTO: plant }
        })
        .then(res => {
          this.dialog = false;
          const newPlant = res.data.savePlant;
          this.addNewElementToRow(newPlant);
        })
        .catch(error => {
          console.log(error);
        });
    },

    addNewElementToRow(newPlant) {
      let index = this.plants.indexOf(this.plants.find(plant => newPlant.id === plant.id));

      if (index === -1) {
        this.plants.push(newPlant);
      } else {
        this.plants[index] = newPlant;
      }
    },

    getStatus(plant) {
      let _status = [];
      _status.push(plant.status.weekly);
      _status.push(plant.status.monthly);
      _status.push(plant.status.yearly);
      return _status;
    },

    openSaveNewPlantDialog() {
      this.dialog = true;
      this.plant = {
        name: "",
        description: ""
      };
      this.dialogTitle = this.translations.newCategory;

      // focus on first input field
      setTimeout(() => {
        this.$refs.name.focus();
      }, 200);
    }
  },

  computed: {
    translations: () => translations,

    disabled() {
      return !this.plant.name || !this.plant.description;
    }
  }
};
</script>

<style scoped>
.grid-plant-section {
  display: grid;
  grid-template-columns: repeat(auto-fill, 320px);
  grid-gap: 10px;
}
</style>
